button.mdl-button {
    font-family: inherit;
}

span.firebaseui-idp-text.firebaseui-idp-text-long {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

div.firebaseui-card-content {
    padding: 1px;
}

button.firebaseui-idp-button {
    border-radius: 10px;
}